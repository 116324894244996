import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';

// import required modules
import { EffectCards, Navigation, Autoplay } from 'swiper/modules';

// import img1 from '../../images/aminities1.webp'
// import img2 from '../../images/aminities2.webp'
// import img3 from '../../images/aminities3.webp'
// import img4 from '../../images/aminities4.webp'
// import img5 from '../../images/aminities5.webp'

import img1 from '../../images/smart2.webp'
import img2 from '../../images/smart3.webp'
import img3 from '../../images/smart21.webp'
import img4 from '../../images/smart31.webp'
import img5 from '../../images/slider4.webp' 




import { useMediaQuery } from 'react-responsive';

const Amenities = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const data = [
        {
            "id":1,
            "pic": img1
        },
        {
            "id":2,
            "pic": img2
        },
        {
            "id":3,
            "pic": img3
        },
        {
            "id":4,
            "pic": img4
        },
        {
            "id":5,
            "pic": img5
        },
    ];
  return (
    <>
        <div className='amenities-section'>
            <div className="space-60"></div>
            <div className="space-60 d-none d-md-block"></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 col-md-12'>
                        <div className="slider">
                            <Swiper
                                effect={'cards'}
                                grabCursor={true}
                                modules={[EffectCards, Navigation]}
                                loop={true}
                                navigation={true}
                                className="mySwiper"
                                initialSlide={2}
                            >
                                {data.map((ele, i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <img src={ele.pic} alt="" className='img-fluid'/>
                                        </SwiperSlide>
                                    )
                                })}
                                
                            </Swiper>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-12 d-flex align-items-center'>
                        <div className={`${isMobile ? 'text-center' : ''}`} style={{paddingLeft: !isMobile ? '9px': ''}}>
                            <h1>Unmatched Amenities for a Modern Lifestyle</h1>
                            <p style={{textAlign: isMobile ? 'center' : 'left'}}>IRA Square offers world-class amenities, including a 25,000 sft, 4-level clubhouse equipped with a gym, indoor sports, and entertainment spaces – all designed to elevate your lifestyle.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="space-60"></div>
            {!isMobile &&
            <div className="space-60"></div>
            }
            <div className="space-60 d-none d-md-block"></div>
        </div>
        
        {/* {!isMobile &&
            <div className="space-60"></div>
        } */}
        
    </>
  )
}

export default Amenities
