import React from 'react'
import img from '../../images/exude.webp'
import img2 from '../../images/01.webp'
import fac1 from '../../images/facing1.webp'
import fac2 from '../../images/facing2.webp'
import fac3 from '../../images/facing3.webp'
import fac4 from '../../images/facing4.png'
import fac5 from '../../images/facing5.png'
import fac6 from '../../images/facing6.png'
import sq1 from '../../images/square1.png'
import sq2 from '../../images/square2.png'
import sq3 from '../../images/square3.png'
import bg from '../../images/facing-bg.png'
import { useMediaQuery } from 'react-responsive';


const Facing = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    return (
        <div className='facing' style={{backgroundColor: '#e0e5c4',backgroundImage: `url(${bg})`, backgroundSize: 'cover',backgroundPosition: 'center',width: '100%'}}>
            {/* <img src={bg} alt="" className='img-fluid' style={{position: 'absolute'}}></img> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 position-relative'>
                        <img src={img} alt="" className='img-fluid'></img>
                        {
                           !isMobile && 
                            <>
                            <img src={sq1} alt="" className='img-fluid' style={{ position: 'absolute', top: '-42px', height: '42px'}}></img>
                            <img src={sq2} alt="" className='img-fluid' style={{ position: 'absolute', bottom: '-42px', height: '42px'}}></img>
                            </>
                        }
                        
                    </div>
                    <div className='col-lg-7 d-flex align-items-center position-relative'>
                        
                        <div className='luxury'>
                            <h1>Spacious 4 BHK Villas with Modern</h1>
                            { 
                                // !isMobile ?
                                // <>
                                //     <span>Step into east and west facing duplex villas that display the finest</span>
                                //     <span>space planning, where a symphony of indoors and outdoors charms </span>
                                //     <span>you with thoughtful additions and meticulous craftsmanship."</span>
                                // </>
                                // :
                                <p>Our villas offer a perfect blend of luxury and space. With a 3700 sft built-up area, these 4 BHK villas are thoughtfully designed with modern architecture, ample natural lighting, and exclusive living spaces.</p>
                            }
                        </div>
                        {
                           !isMobile && 
                            <img src={sq3} alt="" className='img-fluid' style={{position: 'absolute', height: '376px', left: '36%'}}></img>
                        }
                    </div>
                </div>
                <div className="space-60"></div>
                {
                    !isMobile && 
                    <div className="space-60"></div>
                }


                <div className='facing-image' style={{ backgroundColor: "#fff" }}>
                    {!isMobile && 
                    <div className='row'>
                        <div className='col-lg-4 text-start' >
                            <h2>01</h2>
                            <h5 className='text-uppercase '>East Facing Villa</h5>
                            
                        </div>
                        <div className='col-lg-4' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Plot sizes <span className='color-para'>267 Sq.Yds. to 330 Sq.Yds.</span></li>
                                <li>Built-up area of <span className='color-para'>3700 Sft</span></li>
                            </ul>
                            
                        </div>
                        <div className='col-lg-4' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Provision for <span className='color-para'>Elevator</span></li>
                                <li>4-BHK Luxury <span className='color-para'>Duplex Villas + Terrace Garden</span></li>
                            </ul>
                        </div>
                    </div>
                    }
                    {isMobile && 
                        <div className='row'>
                            <div className='col-lg-4 text-start '>
                            <h2>01</h2>
                            <h5 className='text-uppercase '>East Facing Villa</h5>
                        </div>
                        <div className='col-lg-4 ' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Plot sizes <span className='color-para'>267 Sq.Yds. to 330 Sq.Yds.</span></li>
                                <li>Built-up area of <span className='color-para'>3700 Sft</span></li>
                            </ul>
                        </div>
                        <div className='col-lg-4 ' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Provision for <span className='color-para'>Elevator</span></li>
                                <li>4-BHK Luxury <span className='color-para'>Duplex Villas + Terrace Garden</span></li>
                            </ul>
                        </div>
                        
                    </div>
                    }
                    <div className='space-20'></div>
                    <div className='row' style={{ border: "1px solid #8D6554", padding: "3%"}}>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}  >
                            <img src={fac4} alt="" className='w-100'></img>
                        </div>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}>
                            <img src={fac6} alt="" className='w-100'></img>
                        </div>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}>
                            <img src={fac5} alt="" className='w-100' style={{height: '90%'}}></img>
                        </div>
                    </div>
                </div>

                <div className="space-20"></div>
                
                <div className='facing-image' style={{ backgroundColor: "#fff" }}>
                    {!isMobile && 
                    <div className='row'>
                        <div className='col-lg-4' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Plot sizes <span className='color-para'>267 Sq.Yds. to 330 Sq.Yds.</span></li>
                                <li>Built-up area of <span className='color-para'>3700 Sft</span></li>
                            </ul>
                        </div>
                        <div className='col-lg-4' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Provision for <span className='color-para'>Elevator</span></li>
                                <li>4-BHK Luxury <span className='color-para'>Duplex Villas + Terrace Garden</span></li>
                            </ul>
                        </div>
                        <div className='col-lg-4 text-end'>
                            <h2>02</h2>
                            <h5 className='text-uppercase '>West Facing Villa</h5>
                        </div>
                    </div>
                    }
                    {isMobile && 
                        <div className='row'>
                            <div className='col-lg-4 text-end '>
                            <h2>02</h2>
                            <h5 className='text-uppercase '>West Facing Villa</h5>
                        </div>
                        <div className='col-lg-4 ' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Plot sizes <span className='color-para'>267 Sq.Yds. to 330 Sq.Yds.</span></li>
                                <li>Built-up area of <span className='color-para'>3700 Sft</span></li>
                            </ul>
                        </div>
                        <div className='col-lg-4 ' style={{ display: "flex", alignItems: "end"}}>
                            <ul>
                                <li>Provision for <span className='color-para'>Elevator</span></li>
                                <li>4-BHK Luxury <span className='color-para'>Duplex Villas + Terrace Garden</span></li>
                            </ul>
                        </div>
                        
                    </div>
                    }
                    <div className='space-20'></div>
                    <div className='row' style={{ border: "1px solid #8D6554", padding: "3%"}}>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}>
                            <img src={fac1} alt="" className='w-100'></img>
                        </div>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}>
                            <img src={fac3} alt="" className='w-100' style={{height: '90%'}}></img>
                        </div>
                        <div className={`col-lg-4 col-md-12 ${isMobile ? 'mb-3' : ''}`}>
                            <img src={fac2} alt="" className='w-100'></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='space-90'></div>
        </div>
    )
}

export default Facing
