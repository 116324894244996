import React from 'react'
import community from '../../images/community.webp'
import img from '../../images/Ellipse.png'

const AmenitiesBanner = () => {
  return (
    <>
      <div className='square-image'>
            
            <img src={community} alt="" className='img-fluid w-100 full-img'></img>
            <div className="space-20"></div>
            <div>
                <h1 className='text-uppercase'>Exclusive Pricing for Luxury Villas in Adibatla</h1>
                <p>Starting at just ₹8700 per square foot, The Square offers premium 4 BHK villas at an unbeatable price. Contact us to learn more about our flexible payment plans and limited-time offers for early buyers.</p>
            </div> 
            {/* <img src={img} alt="" className='img-fluid w-100'></img>         */}
        </div>
        
    </>
  )
}

export default AmenitiesBanner
